import {formatGetMediumUrl, GET} from "./utils";

export const getStats = (id, startDate, endDate, resolution) => {
    const url = formatGetMediumUrl(id, startDate, endDate, resolution)
    return GET(url, startDate, endDate, resolution)
        .then(function (response) {
            if (response) {
                return response
            }
        })
}