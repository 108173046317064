export const getEnvNode = () => process.env.REACT_APP_ENV

export const OUTSOURCE_VARS = {
    dateRange: {
        week: 'Woche',
        month: 'Monat',
        year: 'Jahr'
    },
    tabs: {
        zev: 'Strom',
        warm: 'Wärme',
        water: 'Wasser'
    },
    resolutions: {
        day: 'DAY',
        month: 'MONTH'
    }
}