import React, {useContext} from "react";
import {ContextState} from "../../ContextState";
import {tabLabels} from "../../config/sharedConfig";
import {fixNum, getPercentage} from "../../utils/numberUtils";
import {KwhHelper} from "../uiKit/KwhHelper";
import {isNumber} from "chart.js/helpers";

const ListComponent = ({selectedTab}) => {
    const rootState = useContext(ContextState)
    const data = rootState.stats

    const isFirstTabSelected = rootState.selectedTab === tabLabels[0]

    const renderFirstListItemPercentage = () => {
        if (rootState.selectedTab === "Strom") {
            return getPercentage(data?.totalConsumption, data?.totalEnergyHighTariff)
        }
        const num1 = Number(data.total1)
        const num2 = Number(data.total2)
        return getPercentage(num1 + num2, num1)
    }
    const renderSecondListItemPercentage = () => {
        if (rootState.selectedTab === "Strom") {
            return getPercentage(data?.totalConsumption, data?.totalEnergyLowTariff)
        }
        const num1 = Number(data.total1)
        const num2 = Number(data.total2)
        return getPercentage(num1 + num2, num2)
    }
    const firstListItemView = () => {
        return selectedTab === tabLabels[0] ?
            'Netzbezug - Hochtarif' :
            selectedTab === tabLabels[1] ?
                'Raumheizung' : 'Warmwasser'
    }
    const secondListItemView = () => {
        return selectedTab === tabLabels[0] ?
            'Netzbezug - Niedertarif' :
            selectedTab === tabLabels[1] ?
                'Kälte' : 'Kaltwasser'
    }
    const thirdListItemView = () => {
        return selectedTab === tabLabels[0] ?
            'Solarstrom' :
            'Kälte'
    }

    const stormView = () => {
        if (isNumber(data?.totalEnergyHighTariff) && !Number(data?.totalEnergyHighTariff)) return null
        if (isNumber(data?.total1) && !Number(data?.total1)) return null
        return <li className="list-group-item f">
            {firstListItemView()} ({renderFirstListItemPercentage()}%)
            <span>{fixNum(isFirstTabSelected ? data?.totalEnergyHighTariff : data.total1)}
                {
                    isFirstTabSelected || (!isFirstTabSelected && data.total1) ?
                        <KwhHelper selectedTab={selectedTab}/> : null
                }
                </span>
        </li>
    }

    const heatingView = () => {
        if (isNumber(data?.totalEnergyLowTariff) && !Number(data?.totalEnergyLowTariff)) return null
        if (isNumber(data?.total2) && !Number(data?.total2)) return null
        return <li className="list-group-item s">
            {secondListItemView()} ({renderSecondListItemPercentage()}%)
            <span>{fixNum(isFirstTabSelected ? data?.totalEnergyLowTariff : data.total2)}
                {
                    isFirstTabSelected || (!isFirstTabSelected && data.total2) ?
                        <KwhHelper selectedTab={selectedTab}/> : null
                }
                    </span>
        </li>
    }

    const solarView = () => {
        if (isNumber(data?.totalProduced) && !Number(data?.totalProduced)) return null
        if ((selectedTab !== tabLabels[2] && selectedTab !== tabLabels[1]))
        return <li className="list-group-item t">
            {thirdListItemView()} ({getPercentage(data?.totalConsumption, data?.totalProduced)}%)
            <span>{fixNum(data?.totalProduced)} <KwhHelper selectedTab={selectedTab}/> </span>
        </li>
    }
    if (!data) return null
    return (
        <>
            {stormView()}
            {heatingView()}
            {solarView()}
        </>
    )
}

export default ListComponent