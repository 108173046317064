import {getBaseUrl} from "../config/domainConfig";
import axios from "axios";

export const GET = (url, startDate, endDate, resolution) => {
    return axios.get(`${getBaseUrl()}${url}`,  {
        params: {
            startDate: startDate,
            endDate: endDate,
            resolution: resolution
        },
        headers: {
            'Content-Type': 'text/html'
        }
    })

}

export const POST = (url, body) => {
    return fetch(`${getBaseUrl()}/${url}`, {
        method: "POST",
        body: body,
        headers: { "Content-Type": "application/json" },
    })
}

export const formatGetMediumUrl = (id, startDate, endDate, resolution) => {
    const url = 'panel-service/customer/v1/meter-readings/panels'
    return url + '/' + id
}