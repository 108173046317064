import React, {useEffect, useContext, useState} from 'react';
import {COLORS, labels, labelsMonth, labelsYear} from "../../config/chartConfig";
import {ContextState} from "../../ContextState";
import {dateRangeOptions, tabLabels} from "../../config/sharedConfig";
import {KwhHelper} from "../uiKit/KwhHelper";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid,} from 'recharts';
import {getMonth} from "../../utils/dateUtils";

export const getRandom = () => Math.floor(Math.random() * (100 - 10 + 1) + 10)

const BarComponent = () => {
    const rootState = useContext(ContextState)
    const data = rootState?.stats?.data
    const [chartData, setChartData] = useState()

    useEffect(() => {
        if (!data) return

        if (rootState.selectedDateRange === dateRangeOptions[0])
            setChartData(labels.map((item, index) => {
                return {
                    name: item,
                    uv: data[index]?.energyLowTariff,
                    pv: data[index]?.energyHighTariff,
                    amt: data[index]?.produced
                }
            }))
        if (rootState.selectedDateRange === dateRangeOptions[1])
            setChartData(labelsMonth.map((item, index) => {
                return {
                    name: item,
                    uv: data[index]?.energyLowTariff,
                    pv: data[index]?.energyHighTariff,
                    amt: data[index]?.produced
                }
            }))
        if (rootState.selectedDateRange === dateRangeOptions[2])
            setChartData(labelsYear.map((item, index) => {
                let formattedData = null
                data.forEach((value) => {
                    if (getMonth(value.dateTime) === index)
                        formattedData = value
                })
                return {
                    name: item,
                    uv: formattedData?.energyLowTariff,
                    pv: formattedData?.energyHighTariff,
                    amt: formattedData?.produced
                }
            }))
    }, [rootState.selectedDateRange, rootState.selectedTab, data])

    const op = {
        scales: {
            xAxes: [{
                afterTickToLabelConversion: function (data) {


                    var xLabels = data.ticks;

                    xLabels.forEach(function (labels, i) {
                        return 'z'
                    });
                }
            }]
        }
    }

    const renderBars = () => {
        if (rootState.selectedTab === tabLabels[0]) {
            return (
                <>
                    <Bar dataKey="pv" stackId="a" fill={COLORS[0]}/>
                    <Bar dataKey="uv" stackId="a" fill={COLORS[1]}/>
                    <Bar dataKey="amt" stackId="a" fill={COLORS[2]}/>
                </>
            )
        } else {
            return (
                <>
                    <Bar dataKey="uv" stackId="a" fill={COLORS[0]}/>
                    <Bar dataKey="pv" stackId="a" fill={COLORS[1]}/>
                </>
            )
        }
    }

    if (rootState.errorApiFlag) return (<div className="ckw-error-text">
        <h6>Keine Daten für diesse Zeitfenster verfügbar</h6>
    </div>)

    return (
        <>
            <div className="ckw-bar-wrapper">
                <p>
                    <KwhHelper selectedTab={rootState.selectedTab}/>
                </p>
                {data && <BarChart
                    width={650}
                    height={350}
                    label={{fill: 'red', fontSize: 20}}
                    animationDuration={2500}
                    data={chartData}
                    barSize={rootState.selectedDateRange === dateRangeOptions[1] ? 5 : 10}
                    margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 5,
                    }}
                    options={op}
                >
                    <CartesianGrid strokeDasharray="0" vertical={false} strokeOpacity={0.3}/>
                    <XAxis dataKey={"name"} stroke={"#e2e2e2"} tickSize={3} fontSize={14} tickLine={false}/>
                    <YAxis tickCount={10} stroke={"#e2e2e2"} fontSize={14} axisLine={false} tickLine={false}/>
                    {renderBars()}
                </BarChart>}
            </div>
        </>
    )
}

export default BarComponent