import moment from "moment";
import "moment/locale/de"
export const startDate = (resolution, date) => moment(date).startOf(resolution).format('YYYY-MM-DD');
export const endDate = (resolution, date) => moment(date).endOf(resolution).format('YYYY-MM-DD');
export const getMonth = (date) => moment(date).month()
export const startDateWeekDe = (resolution, date) => 'Woche vom ' + moment(date).startOf(resolution).locale("de").format('DD MMM');
export const startDateMonthDe = (resolution, date) => moment(date).startOf(resolution).locale("de").format('MMMM YYYY');
export const startDateYearDe = (resolution, date) => moment(date).startOf(resolution).locale("de").format('YYYY');

export const getStartDate = (resolution, date) => {
    switch (resolution) {
        case 'Woche':
            return startDate('isoWeek', date)
        case 'Monat':
            return startDate('month', date)
        default:
            return startDate('year', date)
    }
}

export const getStartDateDe = (resolution, date) => {
    switch (resolution) {
        case 'Woche':
            return startDateWeekDe('isoWeek', date)
        case 'Monat':
            return startDateMonthDe('month', date)
        default:
            return startDateYearDe('year', date)
    }
}

export const getEndDate = (resolution, date) => {
    switch (resolution) {
        case 'Woche':
            return endDate('isoWeek', date)
        case 'Monat':
            return endDate('month', date)
        default:
            return endDate('year', date)
    }
}

export const addMissingDatesInMonth = (datesArray, startDate, isVewa) => {
    const allDates = [];
    const currentDate = new Date(startDate);
    let daysInMonth = moment().daysInMonth()

    while (daysInMonth) {
        allDates.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
        daysInMonth--
    }

    return mapMissingDates(allDates, datesArray, isVewa)
}

export const addMissingDatesInWeek = (datesArray, isVewa, selectedDate) => {
    const weekDates = [];
    const startOfWeek = moment(new Date(selectedDate)).startOf('isoWeek');
    for (let i = 0; i < 7; i++) {
        weekDates.push(startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD'));
    }

    return mapMissingDates(weekDates, datesArray, isVewa)
}

const mapMissingDates = (allDates, datesArray, isVewa) => {
    return allDates.map(date => {
        const existingDateObj = datesArray.find(item => moment(item.dateTime).isSame(date))
        if (existingDateObj)
            return existingDateObj
        return isVewa ? {
            consumption: 0,
            dateTime: date
        } : {
            areaPercentageTotal: "0",
            consumption: "0",
            energyHighTariff: "0",
            energyLowTariff: "0",
            produced: "0",
            dateTime: date
        }
    })
}

export const getStartDateInMonth = (date) => moment(date).startOf('month').format('YYYY-MM-DD');
export const getLastDateInMonth = (date) => moment().endOf('month').format('YYYY-MM-DD');

export const getStartDateInWeek = (date) => moment().startOf('week').format('YYYY-MM-DD');
export const getLastDateInWeek = (date) => moment().endOf('week').format('YYYY-MM-DD');

export const getCurrentDate = () => moment().unix() * 1000
export const getNextDate = (date, resolution) => moment(date).add(handleDatesByResolution(resolution), 'days').format('YYYY-MM-DD');
export const getPreviousDate = (date, resolution) => moment(date).subtract(handleDatesByResolution(resolution), 'days').format('YYYY-MM-DD');

export const isFutureDate = (date) => moment(date).isAfter(moment(), 'day')
export const isCurrentDate = (date) => moment(date).isSame(moment(), 'day')

const handleDatesByResolution = (resolution) => {
    switch (resolution) {
        case 'Woche':
            return 7
        case 'Monat':
            return 30
        default:
            return 365
    }
}

// write a function to get string date and chec if it's valid date
export const isValidDate = (date) => {
    return moment(date, 'YYYY-MM-DD', true).isValid()
}