import App from "../App";
import MockDataPage from "../pages/MockData.Page";
import MockDataImagePage from "../pages/MockDataImage.Page";
import React from "react";

export const routeConfig = [
    {
        path: "/:id",
        element: (
            <App/>
        ),
    },
    {
        path: "mock-data",
        element: <MockDataPage/>,
    },
    {
        path: "mock-data-img",
        element: <MockDataImagePage/>,
    },
]