import React from 'react';
import {COLORS} from "../../config/chartConfig";
import logo from '../../assets/imgs/logo.png'
import {ContextState} from "../../ContextState";
import {useEffect, useContext, useState} from "react";
import {tabLabels} from "../../config/sharedConfig";
import {PieChart, Pie, Cell} from 'recharts';
import {fixNum} from "../../utils/numberUtils";
import {KwhHelper} from "../uiKit/KwhHelper";
import ListComponent from "./ListComponent";
import {OUTSOURCE_VARS} from "../../utils/sharedUtils";

const randomChartDataValue = () => Math.floor(Math.random() * (250 - 30 + 1) + 30);

const DoughnutComponent = () => {
    const rootState = useContext(ContextState)
    const data = rootState?.stats
    const [chartData, setChartData] = useState(null)
    const [addressObj, setAddressObj] = useState(null)
    const isFirstTab = rootState.selectedTab === tabLabels[0]

    useEffect(() => {
        setChartData([
            {
                name: 'Group A',
                value: Number(isFirstTab ? rootState?.stats?.totalEnergyHighTariff : rootState.stats.total1)
            },
            {
                name: 'Group B',
                value: Number(isFirstTab ? rootState?.stats?.totalEnergyLowTariff : rootState.stats.total2)
            },
            {
                name: 'Group C',
                value: rootState.selectedTab === tabLabels[0] ? Number(rootState?.stats?.totalProduced) : 0
            }
        ])
    }, [rootState.stats])

    const renderTotalSum = () => {
        if (rootState.selectedTab === OUTSOURCE_VARS.tabs.zev)
            return fixNum(data?.totalConsumption)
        return fixNum(Number(data?.total1) + Number(data?.total2))
    }

    if (rootState.errorApiFlag) return null
    return (
        <>
            <div className="ckw-doughnut-wrapper">
                <div className="ckw-doughnut">
                    <div className="ckw-doughnut-center-label">
                            <span>
                                {renderTotalSum()}
                            </span>
                        <span><KwhHelper selectedTab={rootState.selectedTab}/></span>
                    </div>
                    {chartData && <PieChart width={150} height={150}>
                        <Pie
                            stroke={0}
                            data={chartData}
                            cx={70}
                            cy={80}
                            innerRadius={40}
                            outerRadius={55}
                            fill="#8884d8"
                            paddingAngle={3}
                            dataKey="value"
                            border={0}
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                            ))}
                        </Pie>
                    </PieChart>}
                </div>
                <ol className="list-group ckw-doughnut-list">
                    <ListComponent selectedTab={rootState.selectedTab}/>
                </ol>
                {addressObj ? <p>{addressObj}</p> : null}
                <div className="ckw-logo">
                    <img src={logo} alt="Ckw Logo"/>
                </div>
            </div>

        </>
    )
}

export default DoughnutComponent