import React, {useContext, useEffect, useState} from 'react';
import HeaderComponent from "../header/HeaderComponent";
import ConsumptionComponent from "../consumption/ConsumptionComponent";
import {getStats} from "../../domain";
import {useParams} from "react-router-dom";
import Loader from "../loader/loader";
import {ContextState, ContextStateDispatch} from "../../ContextState";
import {
    addMissingDatesInMonth,
    addMissingDatesInWeek,
    getEndDate,
    getStartDate,
    getStartDateInMonth, isValidDate
} from "../../utils/dateUtils";
import {VewaMediums} from "../../config/sharedConfig";
import {OUTSOURCE_VARS} from "../../utils/sharedUtils";

const CoreComponent = () => {
    let {id} = useParams();
    let intervalPoint = null
    const rootState = useContext(ContextState);
    const setRootState = useContext(ContextStateDispatch)
    const [loading, setLoading] = useState(false)
    const delay = 3_600_000

    const handleResolution = () => {
        switch (rootState.selectedDateRange) {
            case OUTSOURCE_VARS.dateRange.week:
            case OUTSOURCE_VARS.dateRange.month:
                return OUTSOURCE_VARS.resolutions.day
            default:
                return OUTSOURCE_VARS.resolutions.month
        }
    }

    useEffect(() => {
        if (id && rootState.selectedDateRange && rootState.selectedTab) {
            updateData()
        }
        intervalPoint = setInterval(() => updateData(), delay)

        return () => clearInterval(intervalPoint)
    }, [id, rootState.selectedDateRange, rootState.selectedTab, rootState.selectedDateTime])

    const updateData = () => {
        setLoading(true)
        const startDate = getStartDate(rootState.selectedDateRange, rootState.selectedDateTime)
        const endDate = getEndDate(rootState.selectedDateRange, rootState.selectedDateTime)

        if (!isValidDate(startDate) || !isValidDate(endDate)) {
            return
        }
        getStats(
            id,
            startDate,
            endDate,
            handleResolution()
        )
            .then(response => {
                const formattedResponse = rootState.selectedTab === OUTSOURCE_VARS.tabs.zev ? mapZevData(response.data) : mapVewaData(response.data.vewa)
                setRootState({...rootState, stats: formattedResponse, errorApiFlag: false})
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
                setRootState(state => ({...state, errorApiFlag: true}))
                setLoading(false)
            })
    }

    const mapZevData = (data) => {
        const barChartData = mapDateList(data.zev.data)
        const remapData = {
            totalConsumption: data?.zev?.totalConsumption,
            totalProduced: data?.zev?.totalProduced,
            totalEnergyHighTariff: data?.zev?.totalEnergyHighTariff,
            totalEnergyLowTariff: data?.zev?.totalEnergyLowTariff,
            data: barChartData,
            resolution: data?.zev?.resolution,
        }
        return remapData
    }

    const mapDateList = (data, isVewa) => {
        switch (rootState.selectedDateRange) {
            case OUTSOURCE_VARS.dateRange.week:
                return addMissingDatesInWeek(data, isVewa, rootState.selectedDateTime)
            case OUTSOURCE_VARS.dateRange.month:
                return addMissingDatesInMonth(data, getStartDateInMonth(rootState.selectedDateTime), isVewa)
            default:
                return data
        }
    }

    const mapVewaData = (response) => {
        const total1 = response?.data[rootState.selectedTab === OUTSOURCE_VARS.tabs.warm ? VewaMediums.HEAT : VewaMediums.WARM_WATER]
        const total2 = response?.data[rootState.selectedTab === OUTSOURCE_VARS.tabs.warm ? VewaMediums.COLD : VewaMediums.COLD_WATER]

        const combinedConsumption = {}
        if (total1) {
            for (const item of total1?.data) {
                const dateTime = item.dateTime
                const consumption = parseFloat(item.consumption)

                combinedConsumption[dateTime] = {energyLowTariff: consumption}
            }
        }

        if (total2) {
            for (const item of total2?.data) {
                const dateTime = item.dateTime
                const consumption = parseFloat(item.consumption)

                if (combinedConsumption[dateTime]) {
                    combinedConsumption[dateTime].energyHighTariff = consumption
                } else {
                    combinedConsumption[dateTime] = {energyHighTariff: consumption}
                }
            }
        }

        const combinedArray = Object.keys(combinedConsumption).map((dateTime) => ({
            dateTime,
            ...combinedConsumption[dateTime],
        }))

        return {
            total1: total1?.totalConsumption ?? 0,
            total2: total2?.totalConsumption ?? 0,
            data: mapDateList(combinedArray, true)
        }
    }

    return (
        <>
            <div className="ckw-core-wrapper container-fluid">
                {loading && <Loader></Loader>}
                <HeaderComponent/>
                <ConsumptionComponent/>
            </div>
        </>
    )
}

export default CoreComponent;