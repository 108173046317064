import React from 'react';
import {useContext} from "react";
import {ContextState, ContextStateDispatch} from "../../ContextState";
import {dateRangeOptions} from "../../config/sharedConfig";
import DatePickerComponent from "./DatePickerComponent";

const HeaderComponent = () => {
    const rootState = useContext(ContextState);
    const setRootState = useContext(ContextStateDispatch)

    const setSelectedDateRange = dateRange => setRootState({...rootState, selectedDateRange: dateRange})
    const selectDateRangeView = () => dateRangeOptions.map((item, index) => <button type="button" key={index} className={`btn ${rootState.selectedDateRange === item ? 'btn-secondary' : ''}`} onClick={e => setSelectedDateRange(item)}>{item}</button>)

    return (
        <>
            <div className="row ckw-header-wrapper">
                <div className="col-md-6">
                    <h4 className="ckw-header-title">Überblick Verbrauch</h4>
                </div>
                <div className="col-md-6 ckw-header-buttons">
                    {selectDateRangeView()}
                </div>
                <div className="ckw-header-date-picker">
                    <DatePickerComponent/>
                </div>
            </div>
        </>
    )
}

export default HeaderComponent;