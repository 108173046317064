import React from 'react';
import '../assets/scss/base.scss'
import {ContextStateProvider} from "../ContextState";
import CoreComponent from "../components/core/CoreComponent";
import {DOUGHNUT} from "../config/chartConfig";
import {getRandom} from "../components/charts/BarComponent";


function MockDataPage() {
    return (
        <div className="App app-wrapper">
            <ContextStateProvider>
                <CoreComponent barValue={getRandom} mockData={DOUGHNUT}/>
            </ContextStateProvider>
        </div>
    );
}

export default MockDataPage;