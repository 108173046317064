import {OUTSOURCE_VARS} from "../utils/sharedUtils";

export const dateRangeOptions = [OUTSOURCE_VARS.dateRange.week, OUTSOURCE_VARS.dateRange.month, OUTSOURCE_VARS.dateRange.year]
export const tabLabels = [OUTSOURCE_VARS.tabs.zev, OUTSOURCE_VARS.tabs.warm, OUTSOURCE_VARS.tabs.water]

export const VewaMediums = {
    WARM_WATER : 'WARM_WATER',
    COLD_WATER : 'COLD_WATER',
    HEAT : 'HEAT',
    COLD : 'COLD'
}