import React from 'react';
import '../assets/scss/base.scss'
import logo from "../assets/imgs/logo.png";

function MockDataImagePage() {
    return (
        <div className="App app-wrapper">
            <div className="bg-image">
                <h1 className="bg-image-text">Under construction...</h1>
                <div className="bg-image-logo">
                    <img src={logo} alt="Ckw Logo"/>
                </div>
            </div>
            {/*<ContextStateProvider>*/}
                {/*<CoreComponent barValue={getRandom} mockData={DOUGHNUT}/>*/}
            {/*</ContextStateProvider>*/}
        </div>
    );
}

export default MockDataImagePage;