import React, {useContext, useEffect} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useState} from "react";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import {ContextState, ContextStateDispatch} from "../../ContextState";
import {
    getCurrentDate,
    getNextDate,
    getNextWeek,
    getPreviousDate,
    getPreviousWeek, getStartDate, getStartDateDe,
    isFutureDate
} from "../../utils/dateUtils";

const DatePickerComponent = () => {
    const [startDate, setStartDate] = useState(new Date(getCurrentDate()))
    const rootState = useContext(ContextState)
    const setRootState = useContext(ContextStateDispatch)

    useEffect(() => {
        setRootState({
            ...rootState,
            selectedDateTime: startDate
        })
    }, [startDate])

    const onPreviousDate = () => {
        setStartDate(new Date(getPreviousDate(startDate, rootState.selectedDateRange)))
    }

    const onNextDate = () => {
        const nextDate = getNextDate(startDate, rootState.selectedDateRange)
        if (!isFutureDate(nextDate)) {
            setStartDate(new Date(nextDate))
        }
    }

    return (
        <>
            <div className="ckw-date-picker-wrapper">
                <button type="button" className="btn -left" onClick={onPreviousDate}>
                    <FaChevronLeft/>
                </button>
                <DatePicker readOnly={true} placeholderText={getStartDateDe(rootState.selectedDateRange, rootState.selectedDateTime)} onChange={(date) => setStartDate(date)}/>
                <button type="button" className="btn -right" onClick={onNextDate}>
                    <FaChevronRight/>
                </button>
            </div>
        </>
    )
}

export default DatePickerComponent;