export const fixNum = (num) => {
    if (!num)
        return ""

    return Number(Number(num).toFixed(2)).toLocaleString("de-CH")
}
export const getPercentage = (total, value) => {
    const totalNum = Number(total)
    const valueNum = Number(value)
    const finalValue = valueNum ? (valueNum * 100) / totalNum : 0

    return finalValue.toFixed(2)
}